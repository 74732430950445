import { useCallback, useState } from "react";

import { buildSendDuneTxs } from "@/context/wallet/helpers/sendDune.ts";
import { broadcastAllDunes } from "@/context/wallet/lib/transaction.ts";
import {
  TxWallet,
  TxWithFeeEstimation,
  WalletContextHandleReturn,
} from "@/context/wallet/types.ts";
import { DunesUtxo } from "@/types";
import { handleError } from "@/utility";

type UseSendDuneParams = {
  txWallet: TxWallet | undefined;
  address: string | undefined;
};

export type SendDuneParams = {
  receiverAddress: string;
  tick: string;
  amt: number;
  duneUtxos: DunesUtxo[];
  isClearRequired: boolean;
  feePerVByte: number;
};

export interface SendDuneReturn extends WalletContextHandleReturn {
  execute: (sendDuneParams: SendDuneParams) => Promise<void>;
  txHashes: any[];
}

export const useSendDune = (
  useSendParams: UseSendDuneParams,
): SendDuneReturn => {
  const [isLoading, setIsSendingDune] = useState<boolean>(false);
  const [isError, setIsErrorDuneSend] = useState<boolean>(false);
  const [isSuccess, setIsSuccessDuneSend] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [txHashes, setTxHashes] = useState<any[]>([]);

  const { txWallet, address } = useSendParams;

  const buildSendDunes = useCallback(
    async (
      buildSendDuneParams: SendDuneParams,
    ): Promise<TxWithFeeEstimation[] | undefined> => {
      const estimateFeesOnly = false;
      const buildSendDuneTxsParams = {
        txWallet,
        address,
        estimateFeesOnly,
        ...buildSendDuneParams,
      };

      return buildSendDuneTxs(buildSendDuneTxsParams);
    },
    [address, txWallet],
  );

  const sendDune = useCallback(
    async (sendDuneParams: SendDuneParams) => {
      setIsSendingDune(true);
      setIsErrorDuneSend(false);
      setIsSuccessDuneSend(false);

      try {
        if (!txWallet) {
          throw new Error("No wallet found");
        }

        const txsWithFeeEstimation = await buildSendDunes(sendDuneParams);
        if (!txsWithFeeEstimation) {
          throw new Error("No txs found");
        }

        const txs = txsWithFeeEstimation.map(
          (txWithFeeEstimation) => txWithFeeEstimation.tx,
        );
        const sentTxHashes = await broadcastAllDunes(txs, {
          receiverAddresses: [sendDuneParams.receiverAddress],
          address,
          type: "dune",
        });
        if (
          !sentTxHashes ||
          sentTxHashes.length === 0 ||
          sentTxHashes.length !== txs.length
        ) {
          await txWallet.syncUtxos(true);
          throw new Error("Failed to broadcast transaction");
        }

        for (const tx of txs) {
          txWallet.updateUtxos({ tx });
        }

        setTxHashes(sentTxHashes);

        setIsSuccessDuneSend(true);
      } catch (e: Error | unknown) {
        setIsErrorDuneSend(true);
        const message = handleError(e);
        setError(message);
      } finally {
        setIsSendingDune(false);
      }
    },
    [txWallet, buildSendDunes],
  );

  const reset = useCallback(() => {
    setIsSendingDune(false);
    setIsErrorDuneSend(false);
    setIsSuccessDuneSend(false);
    setError(null);
    setTxHashes([]);
  }, []);

  return {
    isLoading,
    isError,
    isSuccess,
    execute: sendDune,
    reset,
    txHashes,
    error,
  };
};
